.warning {
	width: 800px;
	margin: 24px auto;
	border: 5px solid #ff0000;
	border-radius: 15px;
}

.warning h1 {
	margin-top: 0;
	color: white;
	font-size: 48px;
	line-height: 96px;
	background-color: #ff0000;
	text-transform: uppercase;
}

.warning h2 {
	font-size: 36px;
	text-transform: uppercase;
}

.ck-editor {
	max-width: 100%;
}
